$teal-000: #519387;
$teal-100: #218978;
$teal-200: #46776e;
$teal-300: #035044;
$link-color: #218978;

$callouts: (
        important: ($teal-300, rgba($teal-000, .8), 'Important'),
);

@each $class, $props in $callouts {
  .#{$class} {
    background: nth($props, 2);
    border-left: $border-radius solid nth($props, 1);
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    color: white;

    &::before {
      color: white;
      content: nth($props, 3);
      display: block;
      font-weight: bold;
      font-size: 1em;
      padding-bottom: .125rem;
    }
  }
}

.btn-cb-green {
  color: white !important;
  background-color: #10ac7d;
  background-image: linear-gradient(#218970, #218978);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25), 0 4px 10px rgba(0, 0, 0, 0.12);
}

.btn-cb-green:hover {
  color: #fff;
  background-color: #0fa276;
  background-image: linear-gradient(#12be8b, #0fa276);
}

.btn.btn-cb-green:active {
  color: white;
  background-color: #218978;
  background-image: linear-gradient(#218970, #218978);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25), 0 4px 10px rgba(0, 0, 0, 0.12);
}